import React, { FunctionComponent } from "react"

const SignUpForm: FunctionComponent = () => (
  <div className="text-center">
    <a
      className="
        bg-white  cursor-pointer duration-500 font-bold inline-block px-20 py-4 rounded-full shadow-md text-4 text-blue
        hover:bg-black hover:shadow-lg
      "
      href="/signup"
      title="Sign up for Breezy"
    >
      Book a call
    </a>
  </div>
)

export default SignUpForm

import React, { FunctionComponent, ReactNode } from "react"
import Check from './Check'
import styles from './Comparison.module.css'

type componentOptions = 'td' | 'th'
type valueOptions = ReactNode | 'yes' | 'no' | 'soon'

interface Props {
    section?: boolean
    heading?: boolean
    component?: componentOptions
    label?: string | ReactNode
    valueA?: valueOptions
    valueB?: valueOptions
    valueAAdornment?: ReactNode
    valueBAdornment?: ReactNode
}

const renderValue = {
  yes: <Check />,
  no: <Check variant="off" />,
  soon: <small className={`rounded-full inline-block py-2 px-4 ${styles.soon}`}>Coming soon</small>
}

const ComparisonRow: FunctionComponent<Props> = ({
                                              section,
                                              heading,
                                              component,
                                              label,
                                              valueA,
                                              valueB,
                                              valueAAdornment,
                                              valueBAdornment,
                                            }) => {

    return <tr>
      {React.createElement('th', {align: 'left', scope: 'row', className: `${styles.key} ${(!!section || heading) && styles.heading}`}, label)}
      {React.createElement(component as string, {align: 'center', className: `${styles.colA} ${!!heading && styles.heading}`}, <>{(!heading || (heading && section)) && (React.isValidElement(valueA) ? valueA : renderValue[valueA as string])}{valueAAdornment}</>)}
      {React.createElement(component as string, {align: 'center', className: styles.colB}, <>{(!heading || (heading && section)) && (React.isValidElement(valueB) ? valueB : renderValue[valueB as string])}{valueBAdornment}</>)}
    </tr>
}

ComparisonRow.defaultProps = {
  component: 'td',
  label: '',
  valueA: 'yes',
  valueB: 'yes',
}


export default ComparisonRow
import React, { FunctionComponent, useState } from "react"
import styles from "./Faq.module.css"

interface Props {
  title: string
}

const Answer: FunctionComponent<Props> = ({ title, children }) => {
  const [active, setActive] = useState(false)
  return (
    <li className={`${styles.accordionItem} ${active ? styles.accordionItem__opened : ""} bg-white`} itemScope
        itemProp="mainEntity" itemType="https://schema.org/Question">
      <h3 className={"text-3 md:text-4 px-4 md:px-8 py-4 cursor-pointer m-0 relative flex font-sans"} itemProp="name"
          onClick={() => setActive(!active)}>
        {title}
        <img alt="expand" src="./../images/faq-arrow.svg"
             className={styles.accordionItem__arrow}/>
      </h3>
      <div className={styles.accordionItem__inner} itemScope itemProp="acceptedAnswer"
           itemType="https://schema.org/Answer">
        <div className={`${styles.accordionItem__content} pl-8 pr-16`} itemProp="text">{children}</div>
      </div>
    </li>
  )
}

export default Answer

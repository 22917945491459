import React, { FunctionComponent } from "react"

const NotifyForm: FunctionComponent = () => (
  <div className="text-center">
    <a
      className="
        bg-blue border-4 border-white cursor-pointer duration-500 font-bold inline-block px-12 py-4 rounded-full shadow-md text-4 text-white
        hover:bg-black hover:shadow-lg
      "
      href="/signup"
      title="Book a Demo call"
    >
      Book a Demo
    </a>
  </div>
)

export default NotifyForm

import React, { FunctionComponent, ReactNode, ReactNodeArray } from "react"
import ComparisonRow from "./ComparisonRow"

type componentOptions = 'td' | 'th'
type valueOptions = ReactNode | 'yes' | 'no' | 'coming'

interface Props {
    label: string
    children: ReactNode | ReactNodeArray
}


const ComparisonSection: FunctionComponent<Props> = ({
                                              label,
                                              children,
                                            }) => {

    return <>
      <ComparisonRow label={label} heading />
      {children}
    </>
}

export default ComparisonSection
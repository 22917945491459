import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql, Link } from "gatsby"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"
import Highlight from "../../components/home/Highlight"
import Grid from "../../components/Grid"
import Faq from "../../components/home/Faq"
import styles from "../comparisons.module.css"
import NotifyForm from "../../components/NotifyForm"
import Answer from "../../components/home/Answer"
import Comparison from "../../components/Comparison"
import ComparisonRow from "../../components/ComparisonRow"
import ComparisonSection from "../../components/ComparisonSection"
import SimilarwebIcon from "../../components/icons/SimilarwebIcon"
import SemrushIcon from "../../components/icons/SemrushIcon"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const SemrushPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  // @ts-ignore
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/semrush-alternative"
        title={page.frontmatter.seo.title}
      />

      <header className={`hero`} >
        <Pill bgClass="bg-black" variant="bottom-right">
          <div className="py-16 md:py-24 relative z-10">
            <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center text-white">
              <div className="mb-4 md:mb-8 text-white">
                <h1 className="text-6 md:text-8">Breezy: the best Semrush alternative for brands that want to find <Highlight text=" highly-targeted partnership " /> prospects</h1>
              </div>

              <div className="mb-8 md:mb-10" style={{ maxWidth: "65ch" }}>
                <p className="text-4">At Breezy, we get asked a lot about how we compare to SEO tools like Semrush. Semrush is an all-in-one SEO tool that’s often used for competitor research and keyword discovery. It can be handy for partnership managers, but it’s not a patch on Breezy when it comes to helping you find strategic partners.</p>
              </div>

              <NotifyForm />

            </div>
          </div>
        </Pill>
      </header>


      <section className="md:py-8 lg:py-20 mt-16 mx-auto text-3" style={{maxWidth: '140ch'}}>
        <section className="pb-12">
          <Container>
            <section className={`${styles.splash} p-6 text-4`}>
              <h2 className={"font-serif"}>Semrush vs Breezy</h2>
              <p className="mb-4" style={{maxWidth: '75ch'}}>
                We love Semrush. In fact, we use it ourselves for competitor research and keyword discovery! But while SEO tools like Semrush can be used by partnership managers to uncover partner prospects, this isn’t what they’re made for.
              </p>
            </section>

            <section className="mb-6 md:pt-6 md:pt-24 md:mb-12">
              <Container>
                <Grid className="items-center">
                  <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                    <p className="pb-4">
                      Using Semrush for partner discovery is time-consuming and not particularly intuitive – it’ll involve a lot of exporting data, manually going through it, enriching it and cross-referencing it against different reports. Semrush does have a handy link building tool which can give you useful recommendations for content partners. But it’s not designed to find you leads suitable for other kinds of partnerships, and it won’t give you any data on paid partnerships either.
                    </p>
                    <p className="pb-4">
                      On the other hand, Breezy is a specially designed partner intelligence and discovery platform. That means we’re solely focused on helping you to uncover data-driven partnership opportunities – and we can help you do this (way!) more quickly and easily. That’s right, Breezy is all about cutting down the time you spend searching for leads so you can spend more time making your partnerships a success!
                    </p>
                  </section>

                  <section className="col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1">
                    <img alt="Breezy VS Semrush" src="./../images/BreezyVsSemrush.svg"
                         className="inline-block relative"/>                  </section>
                </Grid>
              </Container>
            </section>
          </Container>
        </section>


        <section className="md:py-16 mx-auto px-4 text-2 md:text-3">
          <h2 className="font-serif text-center md:mb-16">Partner discovery broken&nbsp;down</h2>
          <Comparison className="md:border-1 md:shadow-xl md:p-6" caption="Features comparison of Breezy vs Semrush" competitor="Semrush" compLogo={<SemrushIcon />}>
            <ComparisonSection label="Partner searches">
              <ComparisonRow label="Run searches for backlinking prospects" />
              <ComparisonRow label="View entities that rank for your keywords" valueA={<>View up to <strong>500</strong> keywords at once</>} valueB={<>View one keyword at a time</>} />
              <ComparisonRow label="Run searches for other partner types" valueB="no" />
            </ComparisonSection>
            <ComparisonSection label="Lead data">
              <ComparisonRow label="High impact content URLs" />
              <ComparisonRow label="Relevancy ranking" valueB={<>Backlink prospects only</>} />
              <ComparisonRow label="Domain Authority" valueA={<>Moz Domain Authority</>} valueB={<>Semrush Domain Authority</>} />
              <ComparisonRow label="Traffic & audience data" />
              <ComparisonRow label="Existing partnership data" valueB="no" />
            </ComparisonSection>
            <ComparisonSection label="Market intelligence">
              <ComparisonRow label="Competitor partner analysis" valueB={<>Backlink prospects only</>}/>
              <ComparisonRow label="Analyse your existing partners’ new partnerships" valueB="no" />
              <ComparisonRow label="Insights into the most relevant networks for your brand" valueB="no" />
              <ComparisonRow label="Ecosystem overview" valueA="soon" valueB="no" />
              <ComparisonRow label="Mapped partnerships" valueA="soon" valueB="no" />
              <ComparisonRow label="Gap analysis" valueA="soon" valueB="no" />
            </ComparisonSection>
            <ComparisonRow label="Request leads’ contact information" section valueB={<>Backlink prospects only</>} />
            <ComparisonSection label="Filtering and organisation" >
              <ComparisonRow label="PRM folder sequences" valueB={<>Backlink prospects only</>} />
              <ComparisonRow label="Personalised tagging" valueB={<>Backlink prospects only</>} />
              <ComparisonRow label="Custom lists" valueB="no" />
              <ComparisonRow label="Filter by partner type" valueB="no" />
              <ComparisonRow label="Filter for paid affiliates" valueB="no" />
              <ComparisonRow label="Hide existing partners" valueB="no" />
              <ComparisonRow label="Engage leads with email sequences" valueA="no" valueB={<>Backlink prospects only</>} />
            </ComparisonSection>
            <ComparisonSection label="Support" >
              <ComparisonRow label="Dedicated discovery success manager" valueB="no" />
              <ComparisonRow label="Help with Live Search setup" valueB="no" />
              <ComparisonRow label="Training on the platform" valueB="no" />
            </ComparisonSection>
            <ComparisonRow label="Pricing" section valueA={<span>From £800 per quarter <small>to to run searches based on <strong>40 competitors</strong> & <strong>500 keywords</strong> at once.</small></span>} valueB={<span>From $359.85 per quarter <small>to run searches based on <strong>1 competitor</strong> or <strong>1 keyword</strong> at a time</small></span>}  />
          </Comparison>
        </section>



        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Breezy is the Semrush alternative that lets you find relevant leads (way!) more quickly</h2>
                <p className="mb-4">
                  With Breezy, you can find hyper-relevant partnership prospects for your brand quickly and easily by running powerful partner searches.                </p>
                <p className="mb-4">
                  While Semrush’s link building tool can suggest relevant content partners from just a domain, it’ll take a lot more effort to uncover any other partner type. Why? Well, you’ll have to rely on entering just one keyword at a time to see what entities are ranking top (before exporting and enriching the data manually). Or, you can enter just one competitor at a time to uncover their backlinks and assess whether they could be suitable prospects for you to approach.                </p>
                <p className="mb-4">
                  In contrast, Breezy is the market’s most powerful search engine. Through partner searches, we can analyse and pull together data from up to 40 competitors and 500 keywords all at once, combining these data sets to yield overlap and give you results you know you can rely on – no exporting or cross-referencing required!                </p>

              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-7 ${styles.splash2}`}>
                <img alt="Powerful partner search engine" src="./../images/PowerfulSE.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Unlike Semrush, Breezy is geared towards helping you reach your partner discovery goals</h2>
                <p className="pb-4">
                  We get that every brand is different. So, your partner discovery goals will be different too! To help you make sure leads fit your partnership strategy, we show you detailed insights into each one – from their monthly traffic to their Moz Domain Authority, geographic audience data and Similarweb Global Rank.                </p>
                <p className="pb-4">
                  It’s true that Semrush can reveal a lot of this information too, but it’s not geared towards helping you to understand whether a lead could make a suitable partner for you. For instance, you won’t be able to see whether a lead currently operates as an affiliate, or what affiliate networks they’re on (if any)                </p>
                <p>
                  Breezy’s detailed partnership data will allow you to confirm that a lead can actually help you reach your goals. You’ll be able to view a lead’s current partners, see where those partnerships are taking place and find out whether leads are already partnering with your competitors. This way, you can make sure you’re only considering leads that are highly relevant to your brand and actively interested in working as partners – giving you the best chance of converting leads into collaborators!                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 ${styles.splash2}`}>
                <img alt="Uncover thousands of leads" src="./../images/Uncover.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Say goodbye to hours spent trawling through reams of irrelevant leads</h2>
                <p className="pb-4">Gone are the days of sifting through pages of irrelevant leads. Breezy’s advanced filters, personalised tagging and custom lists make it easy for you to hone in on the partners that are right for your brand – whether you’re only interested in affiliates or you want to view leads with a certain amount of traffic. Plus, once you’re ready to reach out to prospects, you can even request their contact details and we’ll dig them out for you.</p>
                <p className="pb-4">
                  When it comes to backlinking prospects, Semrush has similar PRM folders and can also reveal a lead’s contact information for you. In fact, it will let you message these prospects within the software itself which is cool! However, this won’t apply to other partner types – and besides, you won’t get any info apart from their email addresses.                </p>
                <p>
                  Meanwhile, the quality of the contact information we can find you at Breezy is second to none. We’ll search for job titles, contact names and LinkedIn profiles as well as email addresses. Better still, we find contact details for the leads you like on request, so you can be sure the information we give you is fully up-to-date and accurate!                </p>

              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-7 ${styles.splash2}`}>
                <img alt="Data on every lead" src="./../images/DataEveryLead.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Gain important insights into niche partner ecosystems</h2>
                <p className="pb-4">
                  If you’re going to form a robust partnership strategy for your brand, you’ll need to know where you sit within the wider partnership landscape. Luckily, Breezy is building a one-of-a-kind database to help!                </p>
                <p className="pb-4">
                  Already the biggest partnership database in the world, so far we’ve mapped more than 7M partnerships across 350 networks, 250K brands and 800K content creators. And we’re still going!                 </p>
                <p>
                  Semrush can show you data on other players in your industry and how they’re performing as a whole, but it won’t help you to understand their partnerships. Meanwhile, Breezy’s partnership database is geared towards giving you a high-level understanding of the global ecosystem. This includes the ability to view spend by partnership type and segment the data into niche ecosystems specific to your brand. Winning!                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 relative ${styles.splash2}`}>
                <img alt="Affiliate map" src="./../images/Comp.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
                <h2>Breezy trumps Semrush when it comes to partner-focused competitor research</h2>
                <p className="pb-4">
                  Semrush is a great tool for competitor analysis. It will let you view competitor keywords, see what share of traffic they’re getting compared to you, and reveal where they’re getting their backlinks from. But Breezy is miles ahead when it comes to partner-focused competitor research.
                </p>
                <p className="pb-4">
                  Don’t get us wrong, Semrush’s data can be super handy for partnership managers. But the data isn’t focused on helping you to find partners. Even seeing where a competitor has got its backlinks from is only partially helpful, as Semrush won’t be able to confirm whether any of them are the result of paid partnerships, or if they’re simply organic links!                </p>
                <p>
                  On the other hand, Breezy’s partnership database lets you track your positioning in the global partner ecosystem against competitor brands and reveal the untapped opportunity with powerful gap analysis. You can even view your competitors’ partnerships and where those partnerships are taking place – all data that our powerful partner search engine can use to find you lucrative lookalike opportunities (sshh! Don’t tell!).                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-7 ${styles.splash2}`}>
                <img alt="Discover potential partners" src="./../images/AfffMap.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>

        <section className="py-12 md:py-24">
          <Container>
            <Grid className="items-center">
              <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
                <h2>Enjoy ongoing support from Breezy’s team of experts</h2>


                <p className="pb-4">
                  We want you to succeed as much as you do. So, we offer unparalleled support to businesses like yours, to help you get We want you to love Breezy as much as we do. That’s why we offer brands like yours unparalleled support to help you make the most of the Breezy platform.
                </p>
                <p className="pb-4">
                  We assign every brand a dedicated discovery success manager. They’ll set up your first partner search with you to show you how it’s done. And they’ll give you and your team ample training to ensure you’re confident using Breezy to take your partnership game to the next level.                </p>
                <p>
                  If you’re after a fully hands-off solution, we also offer packages where we can take care of the whole partner recruitment process for you – from identifying the best leads to managing outreach campaigns for your brand. But no matter what plan you’re on, you can be sure our dedicated team of partnership experts has got your back!                </p>
              </section>

              <section className={`col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1 relative ${styles.splash2}`}>
                <img alt="Competitor partner" src="./../images/CS.png" className="inline-block relative" style={{width: '100%'}}/>
              </section>
            </Grid>
          </Container>
        </section>
      </section>

      <section>
          <div className="py-12 md:py-24">
            <ActionPanel>
              <h2 className="mb-6 text-4 md:mb-8 md:text-8">
                Book a demo
              </h2>

              <p className="mb-10 md:mb-16">
                To see exactly what Breezy can do for you and your business, book a free, no-obligation demo with a member of our team. Pssst: you’ll even get some free leads out of it!
              </p>

              <div className="max-w-xl mx-auto">
                <SignUpForm/>
              </div>
            </ActionPanel>
          </div>
      </section>


      <div className="bg-green-100">
          <div className="pt-24 xxl:pt-32 mx-auto" style={{maxWidth: '70ch'}}>
            <h2 className="mb-16 font-serif text-center">Frequently asked questions</h2>
            <ul itemScope itemType="https://schema.org/FAQPage">
              <Answer title="What is the main difference between Breezy and Semrush?">
                <p className="text-3 pb-4">Breezy is a partner discovery and intelligence platform, which means it’s designed to help brands find highly-relevant strategic partners. In contrast, Semrush is an SEO tool that’s primarily focused on helping you to track and improve your visibility on search engines. As such, Semrush has some features that can be helpful for partnership managers – such as a handy link building tool – but isn’t generally designed to help brands find partners.</p>
              </Answer>
              <Answer title="Is Breezy a good alternative to Semrush?">
                <p className="pb-4 text-3">Yes! Breezy isn’t a direct Semrush competitor as Semrush is an all-in-one SEO tool while Breezy’s focus is solely on partner discovery. However, for brands looking to uncover data-driven partnership opportunities, Breezy is a great Semrush alternative.</p>
                <p className="text-3 pb-4">Breezy allows you to run powerful partner searches that pull together data from up to 40 competitors and 500 keywords all at once. Plus, its partnership database allows you to see where you sit within the wider partnership landscape. In this way, Breezy makes it much quicker and easier to uncover highly-relevant leads, so you can spend less time on partner discovery and more time making your partnerships a success</p>
              </Answer>
              <Answer title="How does Breezy compare to Semrush for competitor research?">
                <p className="text-3 pb-4">Breezy and Semrush are both great tools for competitor research. However, Breezy is better at helping you to analyse your competitors’ partnerships. Unlike Semrush, Breezy can show you your competitors’ existing partnerships, where those partnerships are taking place and how you’re tracking in comparison, before helping you to uncover lucrative lookalike opportunities. </p>
              </Answer>
              <Answer title="How can Breezy help with partner discovery?">
                <p className="text-3 pb-4">Breezy is full of features that are specially designed to make partner discovery quicker and easier. These include keyword and competitor-led partner searches, partner intelligence, competitor research and the ability to request contact information for leads you like the look of.</p>
              </Answer>
            </ul>
          </div>
          <div className="py-24 xxl:py-32 text-center">
            <h2>Have more questions?</h2>
            <p className="text-6">Email us at <a href="mailto:welcome@breezy.io">welcome@breezy.io</a></p>
          </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Semrush {
    mdx(fileAbsolutePath: { regex: "//static/breezy-semrush-alternative/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default SemrushPage

import React, { FunctionComponent, ReactChildren, ReactNode, ReactNodeArray } from "react"
import ComparisonRow from "./ComparisonRow"
import BreezyIcon from './icons/BreezyIcon'
import styles from './Comparison.module.css'

interface Props {
    children: ReactNode | ReactNodeArray
    caption: string
    competitor: string
    compLogo: ReactNode,
    className: string
}

const Comparison: FunctionComponent<Props> = ({
                                              className,
                                              children,
                                              caption,
                                              competitor,
                                              compLogo
                                            }) => {
    return (
      <div className={`${styles.root} ${className}`}>
        <table>
          <caption className="hidden">{caption}</caption>
          <thead>
            <ComparisonRow component="th" valueA={<><BreezyIcon /> Breezy</>} valueB={<>{compLogo}{competitor}</>} />
          </thead>
          <tbody>
            {children}
          </tbody>
        </table>
      </div>
    )
}




export default Comparison
import React, { FunctionComponent } from "react"

const ActionPanel: FunctionComponent = ({ children }) => (
  <section className="px-5">
    <div className="bg-blue max-w-screen-xxl mx-auto px-10 py-12 rounded-xl shadow text-center text-white md:shadow-lg md:py-24">
      <div className="max-w-3xl mx-auto">{children}</div>
    </div>
  </section>
)

export default ActionPanel

import React, { FunctionComponent } from "react"
import styles from "./Highlight.module.css"

interface Props {
  text: string
}

const Highlight: FunctionComponent<Props> = ({ text }) => (
  <strong className={styles.root}>{text}</strong>
)

export default Highlight

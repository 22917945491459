import React from 'react'
import s from "./Check.module.css"

type Variant =  "on" | "off" | "disabled"
type Size =  "default" | "small"

interface Props {
  variant?: Variant
}

const sign = {
  on: '&#10003;',
  off: 'X',
  disabled: 'X',
}

const style = {
  on: s.on,
  off: s.off,
  disabled: s.disabled,
}

const Check = ({variant = 'on', size = 'default'}) => (
  <span dangerouslySetInnerHTML={{__html: sign[variant]}} className={`
    ${s.root}
    ${size === 'small' && s.rootSmall}
    ${style[variant]}
  `} />
)

export default Check
